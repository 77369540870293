<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card
          tile
          >

        <v-card-text class="text-center">
        Best 5 Products By Sales (Last 30 Days)
                <BarChartHorizon :height="250" :chartdata="top_product_sale" :options="optionsHorizontalSales" v-if="loading"/>

        </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
         <v-card
          tile
          >
          <v-card-text class="text-center">
              Best 5 Products By Quantity Order (Last 30 Days)
          <BarChartHorizon :height="250" :chartdata="top_product_month" :options="optionsHorizontal" v-if="loading"/>
          </v-card-text>
          </v-card>

      </v-col>

      <v-col cols="12" md="6">
         <v-card
          tile
          v-if="loading"
          >
          <v-card-text class="text-center">
              Last 3 Months Sales
              <BarChart :height="250" :chartdata="chartData" :options="options" v-if="loading"/>
          </v-card-text>
          </v-card>

      </v-col>
      <v-col cols="12" md="6">

            <v-simple-table fixed-header height="350px" >
                <template v-slot:default>
                <thead>
                    <tr>
                        <th colspan="3" class="text-center">5 Less Amount Stock Products</th>
                    </tr>
                    <tr>
                    <th class="text-left">Product</th>
                    <th class="text-left">Stock</th>
                    <th class="text-left">Stock Minimum</th>
                    </tr>
                </thead>

                <tbody>
                    <!-- <v-virtual-scroll
                    :items="transaction_now"
                    :item-height="60"
                    height="250"
                    >
                    -->
                    <!-- <template v-slot:default="{items}"> -->
                        <tr v-for="item in stock" :key="item.id">
                            <td class="text-left" >{{item.product.name}}</td>
                            <td class="text-left" >{{item.stock}}</td>
                            <td class="text-left" >
                                {{item.product.stock_minimum}}
                            </td>
                        </tr>
                    <!-- </template>

                    </v-virtual-scroll>
                    -->
                </tbody>
                </template>
            </v-simple-table>

        </v-col>
    </v-row>


  </div>
</template>

<script>
// @ is an alias to /src
import Progress from '../../../components/external/Progress'
import BarChart from '../../../components/external/BarChart'
import BarChartHorizon from '../../../components/external/BarChartHorizon'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'product.name'
        },
        {
          text: 'Total Penjualan',
          value: 'total_penjualan'
        },
      ],
      stock:[],
      loading:false,
      penjualan:[],
      pembelian:[],
      chartData:{
        labels:[],
        datasets: [
            {
            label: 'Last 3 Months Sales',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            data: []
            },
            {
              label: 'Sales Proyeksi',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              data: []
              }
        ],
      },
      top_product_month:
      {
          labels:[],
          datasets: [
              {
              label: 'Best 5 Products by Quantity Last 30 Days',
              backgroundColor: 'rgba(10, 33, 240, 0.2)',
              data: []
              },
          ],
      },
       top_product_sale:
            {
                labels:[],
                datasets: [
                    {
                    label: 'Best 5 Products by Sales Last 30 Days',
                    backgroundColor: 'rgba(7, 176, 27, 0.2)',
                    data: []
                    },

                ],
            },
      optionsHorizontal: {
          scales: {
              xAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      },
      optionsHorizontalSales: {
          scales: {
              xAxes: [{
                  ticks: {
                      beginAtZero: true,
                      callback: function(value, index, values) {
                          if(parseInt(value) >= 100){
                          return 'Rp.' + value.toString().substring(0, 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '..';
                          } else {
                          return 'Rp.' + value;
                          }
                      }
                  }
              }]
          },
          tooltips: {
              enabled: true,
              callbacks: {
                  label: ((tooltipItems, data) => {
                  console.log(this)
                  return 'Rp. ' + tooltipItems.xLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  })
              }
          }

      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true,
                      callback: function(value, index, values) {
                        if(parseInt(value) >= 100){
                          return 'Rp.' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                          return 'Rp.' + value;
                        }
                      }
                  }
              }]
          },
          tooltips: {
            enabled: true,
            callbacks: {
                label: ((tooltipItems, data) => {
                  console.log(this)
                  return 'Rp. ' + tooltipItems.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                })
            }
          }

      }
    }
  },
  computed: {
    ...mapGetters({
      user:'auth/user',
    })
  },
  components: {
    BarChart,BarChartHorizon
  },
  methods: {
    async go() {
      await this.axios.get('/v1/dashboard?company_id='+this.user.company_id)
      .then(async (ress) => {
        console.log(ress)
        this.penjualan = ress.data.penjualan
        this.pembelian = ress.data.pesanan
        this.stock = ress.data.stock
        await ress.data.pesanan.forEach(element => {
            this.top_product_month.labels.push(element.product.name)
            this.top_product_month.datasets[0].data.push(element.total_penjualan)
        });
        await ress.data.penjualan.forEach(element => {
            this.top_product_sale.labels.push(element.product.name)
            this.top_product_sale.datasets[0].data.push(element.total_penjualan)
        });
        await ress.data.sales_three_month.forEach( (element,index) => {
          this.chartData.labels.push(element.month + '-' + element.year)
          this.chartData.datasets[0].data.push(element.total_penjualan)
          if (ress.data.sales_three_month[index+1]) {
            this.chartData.datasets[1].data.push('')
          } else {
             let date = new Date()
            let now = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
            let total_sales = (element.total_penjualan / date.getDate()) * now
            this.chartData.datasets[1].data.push(total_sales.toFixed(2))

          }

        });
      })
      .catch((err) => {
        console.log(err.response)
      })
      this.loading = true
    }
  },
  async created() {
    let date = new Date()
    let now = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
    console.log(now)
    console.log(date.getDate())
  },
  async mounted () {
    await this.go()
  }
}
</script>
